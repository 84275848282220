body {
  margin: 0;
  padding: 0;
  font-family: BwSeidoRound, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.App {

}

.hidden{
  display: none;
}

.option-buttons{
  padding-top: 10px;
}

.option-buttons input{
  margin: 0 5px;
}

.error{
  color: red;
}

.error, .processed{
  padding-bottom: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-query{
  width: 700px;
  font-size: larger;
}

.query-options{
  display: block;
  width: 700px
}

.button-query{
  font-size: larger;
}

h1{
  margin: 5px 0;
}

h1 a, h1 a:hover, h1 a:visited{
  text-decoration: none;
  color: #000;
}

.github-hint{
  padding: 10px 0 0 20px;
  color: rgba(0, 0, 0, 0.8);
}

.json-response{
  display: inline-block;
  padding-left: 20px;
}

@font-face {
  font-family: BwSeidoRound;
  src: url(./fonts/389947_6_0.eot);
  src: url(./fonts/389947_6_0.eot?#iefix) format("embedded-opentype"), url(./fonts/389947_6_0.woff2) format("woff2"), url(./fonts/389947_6_0.woff) format("woff"), url(./fonts/389947_6_0.ttf) format("truetype");
  font-weight: 500;
  font-style: normal
}
